/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import TextContainer from "../Content/TextContainer";

import { media, Breakpoint, themeColor } from "../../theme";

const Header = styled.p`
  font-family: ${({ theme }) => theme.font.josefinSans};
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
  text-align: center;
  color: ${themeColor("grey700")};
  margin: 0 0 10px;

  ${media(Breakpoint.Tablet)} {
    margin: 0 0 20px;
    text-align: left;
  }
`;

const Address = styled.div``;
const Contact = styled.div``;

const GridInner = styled.div`
  display: grid;
  grid-gap: 40px;

  ${media(Breakpoint.Tablet)} {
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-width: 670px;
    margin: 0 auto;
  }
`;

const ContactTextContainer = styled(TextContainer)`
  p {
    color: ${themeColor("grey700")};
    text-align: center;

    ${media(Breakpoint.Tablet)} {
      text-align: left;
    }

    a {
      color: ${themeColor("grey700")};
      text-decoration: none;
    }
  }
`;

interface ContactDataProps {
  className?: string;
  address: string;
  email: string;
  phone: string;
  phoneAnchor: string;
  instagram?: string;
}

const ContactData: React.FC<ContactDataProps> = ({ className, address, email, phone, phoneAnchor, instagram }) => {
  return (
    <div className={className}>
      <GridInner>
        <Address>
          <Header>Address</Header>
          <ContactTextContainer dangerouslySetInnerHTML={{ __html: address }} />
        </Address>
        <Contact>
          <Header>Reach Us</Header>
          <ContactTextContainer>
            <p>
              Email: <a href={`mailto:${email}`}>{email}</a>
              <br />
              Phone: <a href={`tel:${phoneAnchor}`}>{phone}</a>
              {instagram && (
                <React.Fragment>
                  <br />
                  Instagram:{" "}
                  <a href={`https://instagram.com/${instagram}/`} target="_blank" rel="noopener noreferrer">
                    @{instagram}
                  </a>
                </React.Fragment>
              )}
            </p>
          </ContactTextContainer>
        </Contact>
      </GridInner>
    </div>
  );
};

export default styled(ContactData)`
  position: relative;
  padding: 40px 20px;
  width: 100%;
  max-width: 335px;
  background: ${themeColor("white")};

  ${media(Breakpoint.Tablet)} {
    padding: 100px 20px;
    max-width: 900px;
  }
`;
