/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import React from "react";
import { Query } from "../../gatsby-types";
import Seo from "gatsby-plugin-wpgraphql-seo";

import ContactData from "../components/ContactData";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SectionGrey from "../components/SectionGrey";

import { media, Breakpoint } from "../theme";

type PropsType = {
  data: Query;
};

type acfContactProps = {
  address: string;
  hero: string;
  email: string;
  phone: string;
  phoneAnchor: string;
  instagram?: string;
};

const Contact: React.FC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfContact) {
    return null;
  }

  const { address, hero, email, phone, phoneAnchor, instagram } = props.data.wpPage.acfContact as acfContactProps;

  return (
    <Layout darkHeader>
      <Seo post={props.data.wpPage} />
      <Hero page="contact" header={hero} />
      <SectionGrey>
        <ContactData
          css={{ margin: "0 auto", [media(Breakpoint.DesktopLarge)]: { marginTop: "-100px" } }}
          address={address}
          email={email}
          instagram={instagram}
          phone={phone}
          phoneAnchor={phoneAnchor}
        />
      </SectionGrey>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ContactPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfContact {
        address
        hero
        email
        phone
        phoneAnchor: phoneanchor
        instagram
      }
    }
  }
`;

export default Contact;
